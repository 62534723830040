import React, { useEffect, useState } from 'react';
import '../styles/ViewList.css';
import { checkOnAuthStateChanged, cloudFunctionV2, getAPIGatewayDetail, getUserDocuments, goToCreativeCheckout, recordEvent, sendPropertiesForExcel, submitCreateList, submitDeleteProperties, submitListChanges, submitListDeletion, submitListRename, submitPropertyNote } from '../functions';
import { ExitScreen, Footer, Header, Loading, NewPropertyCard, SideNavigation } from '../components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BulkAction, IconButton, Modal, PropertiesPagination, Toast, ZeroState } from '../v4/components';
import { AddToList, DeleteList, DeleteProperties, DeleteProperty, EditNote, LinkList, SingleInput, Upgrade } from '../v4/modalbody';
import { ChevronThick, Download, TickCircle, Share, HeartActive } from '../assets/icons';
import { colour, defaultUserData, formatterLong } from '../styles/GlobalStyles';
import { Edit02Icon, Settings01Icon, Delete02Icon } from 'hugeicons-react';

function ViewList() {
    const params = useParams();
	const listId = params.id;
    const location = useLocation();
    const state = location.state;
    const [userId, setUserId] = useState("");
    const [userData, setUserData] = useState(state !== null && state.userData !== undefined ? state.userData : null);
    const [subscriptions, setSubscriptions] = useState(state !== null && state.subscriptions !== undefined ? state.subscriptions : []);
    const [list, setList] = useState(state !== null && state.list !== undefined ? state.list : null);
    const [properties, setProperties] = useState(state !== null && state.properties !== undefined ? state.properties : []);
    const [listName, setListName] = useState(list !== null ? list.title : "");
    const [lists, setLists] = useState(state !== null && state.lists !== undefined ? state.lists : []);
    const [favourites, setFavourites] = useState(state !== null && state.favourites !== undefined ? state.favourites : []);
    const [showExitScreen, setShowExitScreen] = useState(false);
    const [loading, setLoading] = useState(true);
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    const propLimit = 8;
    const [settingsModal, setSettingsModal] = useState(false);
    const [renameModal, setRenameModal] = useState(false);
    const [renameLoading, setRenameLoading] = useState(false);
    const [deleteListModal, setDeleteListModal] = useState(false);
    const [deleteListAndProps, setDeleteListAndProps] = useState(false);
    const [deleteListLoading, setDeleteListLoading] = useState(false);
    const [noteModal, setNoteModal] = useState(false);
    const [noteZpid, setNoteZpid] = useState("");
    const [newNote, setNewNote] = useState("");
    const [noteLoading, setNoteLoading] = useState(false);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [listModal, setListModal] = useState(false);
    const [selectedList, setSelectedList] = useState([]);
    const [createListModal, setCreateListModal] = useState(false);
    const [newListName, setNewListName] = useState("");
    const [listLoading, setListLoading] = useState(false);
    const [deletePropertiesModal, setDeletePropertiesModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [unfavouriteModal, setUnfavouriteModal] = useState(false);
    const [unfavouriteProperty, setUnfavouriteProperty] = useState(null);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [downloadComplete, setDownloadComplete] = useState(false);
    const [downloadError, setDownloadError] = useState(false);
    const [downloadLink, setDownloadLink] = useState(null);
    const [premiumModal, setPremiumModal] = useState(false);
    const [propertiesOnPageChange, setPropertiesOnPageChange] = useState(false);
    const [page, setPage] = useState(1);
    const [allProperties, setAllProperties] = useState([]);
    const navigate = useNavigate();

    const settingsLinks = [
        {
            label: "Download list",
            icon: <Download className="" fill={colour.blueBlue03} />,
            chevron: false,
            func: () => downloadProperties(),
            standardIcon: false
        },
        {
            label: "Get shareable link",
            icon: <Share className="" colour={colour.blueBlue03} />,
            chevron: true,
            func: null,
            standardIcon: true
        },
        {
            label: "Rename",
            icon: <Edit02Icon size={16} color={colour.blueBlue03} />,
            chevron: true,
            func: () => openRenameModal(),
            standardIcon: false
        },
        {
            label: "Delete",
            icon: <Delete02Icon size={16} color={colour.blueBlue03} />,
            chevron: true,
            func: () => openDeleteListModal(),
            standardIcon: false
        }
    ];

    useEffect(() => {
        const fetchUserData = async() => {
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                setUserId(user.userId);
                if ( state !== null && state.listVals === true ) {
                    document.title = `Coffee Clozers | ${list.title}`;
                }

                const collections = [
                    "Users",
                    "Subscriptions",
                    "Lists",
                    "Favourites"
                ];
                const queryData = await getUserDocuments(collections, user.userId);
                if ( queryData[0].status === 200 ) {
                    setUserData(queryData[0].data);
                }
                if ( queryData[1].status === 200 ) {
                    setSubscriptions(queryData[1].data);
                }

                if ( queryData[2].status === 200 ) {
                    setLists(queryData[2].data);
                    const listIndex = queryData[2].data.findIndex(e => e.id === listId)
                    if ( listIndex !== -1 ) {
                        setList(queryData[2].data[listIndex]);
                        setListName(queryData[2].data[listIndex].title);
                        document.title = `Coffee Clozers | ${queryData[2].data[listIndex].title}`;

                        const props = queryData[2].data[listIndex].properties;
                        if ( queryData[3].status === 200 ) {
                            const favouriteProps = queryData[3].data;
                            for (let index = 0; index < props.length; index++) {
                                const element = props[index];
                                const favouriteIndex = favouriteProps.findIndex(e => e.zpid === element.zpid);
                                if ( favouriteIndex !== -1 ) {
                                    props[index].note = favouriteProps[favouriteIndex].note;
                                }
                            }
                        }
                        await queryProperties(props);
                    }
                    else {
                        navigate("/properties");
                    }
                }
                else {
                    navigate("/properties");
                }

                if ( queryData[3].status === 200 ) {
                    setFavourites(queryData[3].data);
                }
            }
        };

        const queryProperties = async(props) => {
            setAllProperties(props);
            const userSettings = defaultUserData.settings;
            const minVal = (page - 1) * propLimit;
            const maxVal = page * propLimit;
            const propertiesInScope = props.slice(minVal, maxVal);

            const newProps = [];
            for (let index = 0; index < propertiesInScope.length; index++) {
                const zpid = propertiesInScope[index].zpid;
                const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
                const getGatewayQueryString = await getAPIGatewayDetail(null, zpid, userSettings);
                const getGatewayParams = {
                    type: "gateway",
                    resourceId: "detail",
                    queryString: getGatewayQueryString
                };
                const getGateway = await cloudFunctionV2(gatewayURL, getGatewayParams);
                if ( getGateway.status === 200 ) {
                    const propertyObject = getGateway.body.prop;
                    if ( propertyObject === null ) {
                        continue;
                    }
                    const propertyInScope = propertiesInScope[index];
                    propertyObject.note = propertyInScope.note !== undefined && propertyObject.note !== null ? propertiesInScope[index].note : null;
                    newProps.push(propertyObject);
                }
            };
            setProperties(newProps);
            setLoading(false);
        };

        const pageChangeLoad = async() => {
            await queryProperties(allProperties);
            setLoading(false);
        };

        if ( userId === "" ) {
            fetchUserData();
        }
        else if ( propertiesOnPageChange === true ) {
            setPropertiesOnPageChange(false);
            setLoading(true);
            pageChangeLoad();
        }

    }, [userId, list?.title, listId, state, navigate, propLimit, allProperties, propertiesOnPageChange, page]);

    const exitScreenClick = () => {
        setShowExitScreen(false);
        // setDropdownOpen(false);
    };

    const backToProps = () => {
        navigate("/properties");
    };

    // const sortOptions = [
    //     {
    //         selected: false,
    //         label: "Alphabetically",
    //         disabled: false
    //     },
    //     {
    //         selected: false,
    //         label: "Alphabetically",
    //         disabled: false
    //     },
    //     {
    //         selected: false,
    //         label: "Alphabetically",
    //         disabled: false
    //     }
    // ];

    // const toggleDropdown = () => {
    //     setDropdownOpen(!dropdownOpen);
    //     setShowExitScreen(!dropdownOpen);
    // };

    // const selectSorting = () => {

    // };

    const openSettings = () => {
        setSettingsModal(true);
    }

    const closeSettingsModal = () => {
        setSettingsModal(false);
    };

    const openRenameModal = () => {
        setRenameModal(true);
        setSettingsModal(false);
    };

    const closeRenameModal = () => {
        setRenameModal(false);
        setListName(list.title);
    };
    
    const saveRename = async() => {
        await submitListRename(
            setRenameLoading,
            setRenameModal,
            setList,
            userId,
            listId,
            listName
        );
    };

    const openDeleteListModal = () => {
        setDeleteListModal(true);
        setSettingsModal(false);
    };

    const closeDeleteListModal = () => {
        setDeleteListModal(false);
        setDeleteListAndProps(false);
    };

    const saveDeleteList = async() => {
        recordEvent("Delete List", {
            id: listId
        });
        await submitListDeletion(
            setDeleteListLoading,
            setDeleteListModal,
            setDeleteListAndProps,
            userId,
            listId,
            deleteListAndProps
        );

        navigate("/properties");
    };

    const checkSelected = (id) => {
        const selectedIndex = selectedProperties.indexOf(id);
        if ( selectedIndex !== -1 ) {
            return true;
        }
        else {
            return false;
        }
    };

    const toggleSelected = (id) => {
        const selectedIndex = selectedProperties.indexOf(id);
        if ( selectedIndex !== -1 ) {
            setSelectedProperties(selectedProperties.filter(e => e !== id));
        }
        else {
            setSelectedProperties([...selectedProperties, id]);
        }
    };

    const openNoteModal = (zpid) => {
        setNoteModal(true);
        setNoteZpid(zpid);
        
        // add existing note to newNote
        const favouriteIndex = favourites.findIndex(e => e.zpid === zpid);
        if ( favouriteIndex !== -1 ) {
            setNewNote(favourites[favouriteIndex].note !== undefined && favourites[favouriteIndex].note !== null ? favourites[favouriteIndex].note : "");
        }
    };

    const closeNoteModal = () => {
        setNoteModal(false);
        setNoteZpid("");
        setNewNote("");
    };

    const submitNote = async() => {
        await submitPropertyNote(
            setNoteLoading,
            setNewNote,
            setNoteModal,
            setNoteZpid,
            userId,
            noteZpid,
            newNote,
            properties,
            setProperties
        );
    };

    const closeListModal = () => {
        setListModal(false);
        setSelectedList([]);
    };

    const showCreateListModal = async() => {
        setCreateListModal(true);
        setListModal(false);
    };

    const closeCreateListModal = () => {
        setCreateListModal(false);
    };

    const createList = async() => {
        recordEvent("Create List", {});
        await submitCreateList(
            setListLoading,
            setLists,
            setCreateListModal,
            setListModal,
            userId,
            newListName
        );
    };


    const confirmList = async() => {
        const updateData = await submitListChanges(
            setListLoading,
            setLists,
            setListModal,
            setSelectedList,
            setSelectedProperties,
            userId,
            selectedList,
            selectedProperties,
            properties
        );
        if ( updateData.status === 200 ) {
            const resData = updateData.data;
            const listIndex = resData.findIndex(e => e.id === listId);
            if ( listIndex !== -1 ) {
                const listProps = resData[listIndex].properties;
                
                const newProps = [];
                for (let index = 0; index < properties.length; index++) {
                    const element = properties[index];
                    const listPropIndex = listProps.findIndex(e => e.zpid === element.zpid);
                    if ( listPropIndex !== -1 ) {
                        newProps.push(element);
                    }
                };
                setProperties(newProps);
                setList(resData[listIndex]);

                recordEvent("Changed Properties In List", {
                    properties: newProps.length
                });
            }
        }
    };

    const openDeletePropertiesModal = () => {
        setDeletePropertiesModal(true);
    };

    const closeDeletePropertiesModal = () => {
        setDeletePropertiesModal(false);
    };

    const deleteProperties = async() => {
        recordEvent("Delete Properties In List", {});
        await submitDeleteProperties(
            userId,
            selectedProperties,
            setDeletePropertiesModal,
            setDeleteLoading,
            setSelectedProperties,
            setProperties,
            properties,
            setLists,
            setSelectedList,
            listId,
            setList
        );
    };

    const closeUnfavouritePropertiesModal = () => {
        setUnfavouriteModal(false);
        setUnfavouriteProperty(null);
    };

    const openUnfavouritePropertiesModal = (property) => {
        setUnfavouriteModal(true);
        setUnfavouriteProperty(property);
    };

    const deleteSingleProperty = async() => {
        await submitDeleteProperties(
            userId,
            [unfavouriteProperty.zpid],
            setDeletePropertiesModal,
            setDeleteLoading,
            setSelectedProperties,
            setProperties,
            properties,
            setLists,
            setSelectedList,
            listId,
            setList
        );
        setUnfavouriteModal(false);
        setUnfavouriteProperty(null);
    };

    const closeToast = () => {
        setDownloadLoading(false);
        setDownloadComplete(false);
        setDownloadLink(null);
        setDownloadError(false);
    };

    const openCSVDownload = () => {
        window.open(downloadLink, "_blank");
    };

    const downloadProperties = async() => {
        setDownloadLoading(true);
        setDownloadError(false);
        const downloadData = await sendPropertiesForExcel(properties, `${list.title}`, null, 20, 0);
		if ( downloadData.status === 200 ) {
			window.open(downloadData.data, "_blank");
            setDownloadLoading(false);
            setDownloadComplete(true);
            setDownloadError(false);
        }
        else {
            setDownloadError(true);
        }
    };

    const closePremiumModal = () => {
        setPremiumModal(false);
    };

    const viewPricing = () => {
        navigate("/pricing");
    };

    const upgradeNow = () => {
        goToCreativeCheckout(navigate);
    };

    return (
        <div className="view-list-outer-container">
            <Header
                subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
                mobileNav={true}
                desktopControl={true}
            />
            <div className="view-list-inner-container relative-container">
                {
                    showExitScreen === true &&
                    <ExitScreen
                        clickScreen={() => exitScreenClick()}
                    />
                }
                {
                    (settingsModal === true || renameModal === true || deleteListModal === true || noteModal === true || listModal === true || createListModal === true || unfavouriteModal === true) &&
                    <div className="dark-bg">
                    </div>
                }
                {
                    premiumModal === true &&
                    <Modal
                        heading="Plus Feature 👀 "
                        subheading={"You've discovered a Plus Plan feature!"}
                        body={<Upgrade />}
                        type="secondary"
                        cancelText="Maybe later"
                        cancelButtonCTA={closePremiumModal}
                        actionButtonText="Upgrade now"
                        actionButtonCTA={upgradeNow}
                        actionButtonDisabled={false}
                        secondaryButtonText="See pricing plans"
                        secondaryButtonCTA={viewPricing}
                        exitButtonCTA={closePremiumModal}
                        loading={false}
                        leftIcon={null}
					    rightIcon={null}
                        cancelLeftIcon={null}
                        cancelRightIcon={null}
                        customClass={null}
                    />
                }
                <SideNavigation
                    navigate={navigate}
                    state={state}
                    userData={userData}
                    subscriptions={subscriptions}
                />
                <div className="view-list-body-container">
                    {
                        loading === true ?
                        <Loading />
                        :
                        <div className="view-list-inner-body-container">
                            {
                                settingsModal === true &&
                                <Modal
                                    heading="Settings"
                                    subheading={null}
                                    body={<LinkList links={settingsLinks} />}
                                    type={"single"}
                                    cancelText={null}
                                    cancelButtonCTA={null}
                                    actionButtonText={"Close"}
                                    actionButtonCTA={closeSettingsModal}
                                    actionButtonDisabled={false}
                                    secondaryButtonText=""
                                    secondaryButtonCTA={null}
                                    exitButtonCTA={closeSettingsModal}
                                    loading={false}
                                    leftIcon={null}
					                rightIcon={null}
                                    cancelLeftIcon={null}
                                    cancelRightIcon={null}
                                    customClass={"add-to-list-modal"}
                                />
                            }
                            {
                                renameModal === true &&
                                <Modal
                                    heading="Rename"
                                    subheading={null}
                                    body={<SingleInput val={listName} setVal={setListName} placeholder="Name your list" />}
                                    type="basic"
                                    cancelText="Cancel"
                                    cancelButtonCTA={closeRenameModal}
                                    actionButtonText="Save changes"
                                    actionButtonCTA={saveRename}
                                    actionButtonDisabled={listName === "" || renameLoading === true ? true : false}
                                    secondaryButtonText=""
                                    secondaryButtonCTA={null}
                                    exitButtonCTA={closeRenameModal}
                                    loading={renameLoading}
                                    leftIcon={null}
					                rightIcon={null}
                                    cancelLeftIcon={null}
                                    cancelRightIcon={null}
                                    customClass={"add-to-list-modal"}
                                />
                            }
                            {
                                deleteListModal === true &&
                                <Modal
                                    heading="Delete list"
                                    subheading={null}
                                    body={<DeleteList title={listName} selected={deleteListAndProps} setSelected={setDeleteListAndProps} />}
                                    type="destructive"
                                    cancelText="Cancel"
                                    cancelButtonCTA={closeDeleteListModal}
                                    actionButtonText={deleteListAndProps === true ? "Delete list & properties" : "Delete list only"}
                                    actionButtonCTA={saveDeleteList}
                                    actionButtonDisabled={deleteListLoading}
                                    secondaryButtonText=""
                                    secondaryButtonCTA={null}
                                    exitButtonCTA={closeDeleteListModal}
                                    loading={deleteListLoading}
                                    leftIcon={null}
					                rightIcon={null}
                                    cancelLeftIcon={null}
                                    cancelRightIcon={null}
                                    customClass={"add-to-list-modal"}
                                />
                            }
                            {
                                noteModal === true &&
                                <Modal
                                    heading="Edit note"
                                    subheading={null}
                                    body={<EditNote val={newNote} setVal={setNewNote} placeholder="Write a note on this listing" />}
                                    type={"basic"}
                                    cancelText={"Cancel"}
                                    cancelButtonCTA={closeNoteModal}
                                    actionButtonText={"Save"}
                                    actionButtonDisabled={noteLoading === true || newNote === "" ? true : false}
                                    actionButtonCTA={submitNote}
                                    secondaryButtonText=""
                                    secondaryButtonCTA={null}
                                    exitButtonCTA={closeNoteModal}
                                    loading={noteLoading}
                                    leftIcon={null}
					                rightIcon={null}
                                    cancelLeftIcon={null}
                                    cancelRightIcon={null}
                                    customClass={"add-to-list-modal"}
                                />
                            }
                            {
                                listModal === true &&
                                <Modal
                                    heading="Add to list"
                                    subheading={null}
                                    body={lists.length === 0 ? <ZeroState icon={<HeartActive className="zero-state-icon" fill={colour.grayScaleGray02} />} standardIcon={false} title="No lists yet" subtitle="Create a list to start organizing your properties." showButton={false} /> : <AddToList lists={lists} favourites={favourites} selectedList={selectedList} setSelectedList={setSelectedList} />}
                                    type={lists.length === 0 ? "single" : "secondary"}
                                    cancelText={lists.length === 0 ? "Cancel" : "Clear selection"}
                                    cancelButtonCTA={closeListModal}
                                    actionButtonText={lists.length === 0 ? "Create list" : "Confirm selection"}
                                    actionButtonCTA={lists.length === 0 ? showCreateListModal : confirmList}
                                    actionButtonDisabled={listLoading === true ? true : false}
                                    secondaryButtonText="New list"
                                    secondaryButtonCTA={showCreateListModal}
                                    exitButtonCTA={closeListModal}
                                    loading={listLoading}
                                    leftIcon={null}
					                rightIcon={null}
                                    cancelLeftIcon={null}
                                    cancelRightIcon={null}
                                    customClass={"add-to-list-modal"}
                                />
                            }
                            {
                                createListModal === true &&
                                <Modal
                                    heading="Create list"
                                    subheading={null}
                                    body={<SingleInput val={newListName} setVal={setNewListName} placeholder="Name your list" />}
                                    type={"basic"}
                                    cancelText={"Cancel"}
                                    cancelButtonCTA={closeCreateListModal}
                                    actionButtonText={"Create list"}
                                    actionButtonDisabled={newListName === "" ? true : false}
                                    actionButtonCTA={createList}
                                    secondaryButtonText=""
                                    secondaryButtonCTA={null}
                                    exitButtonCTA={closeCreateListModal}
                                    loading={listLoading}
                                    cancelLeftIcon={null}
                                    cancelRightIcon={null}
                                    customClass={"add-to-list-modal"}
                                />
                            }
                            {
                                deletePropertiesModal === true &&
                                <Modal
                                    heading="Remove properties"
                                    subheading={null}
                                    body={<DeleteProperties selectedProperties={selectedProperties} />}
                                    type={"destructive"}
                                    cancelText={"Cancel"}
                                    cancelButtonCTA={closeDeletePropertiesModal}
                                    actionButtonText={"Remove properties"}
                                    actionButtonDisabled={deleteLoading === true ? true : false}
                                    actionButtonCTA={deleteProperties}
                                    secondaryButtonText=""
                                    secondaryButtonCTA={null}
                                    exitButtonCTA={closeDeletePropertiesModal}
                                    loading={deleteLoading}
                                    leftIcon={null}
					                rightIcon={null}
                                    cancelLeftIcon={null}
                                    cancelRightIcon={null}
                                    customClass={"add-to-list-modal"}
                                />
                            }
                            {
                                unfavouriteModal === true &&
                                <Modal
                                    heading="Remove property"
                                    subheading={null}
                                    body={<DeleteProperty property={unfavouriteProperty} />}
                                    type={"destructive"}
                                    cancelText={"Cancel"}
                                    cancelButtonCTA={closeUnfavouritePropertiesModal}
                                    actionButtonText={"Remove property"}
                                    actionButtonDisabled={deleteLoading === true ? true : false}
                                    actionButtonCTA={deleteSingleProperty}
                                    secondaryButtonText=""
                                    secondaryButtonCTA={null}
                                    exitButtonCTA={closeUnfavouritePropertiesModal}
                                    loading={deleteLoading}
                                    leftIcon={null}
					                rightIcon={null}
                                    cancelLeftIcon={null}
                                    cancelRightIcon={null}
                                    customClass={"add-to-list-modal"}
                                />
                            }
                            <div className="view-list-outer-top-container">
                                <div 
                                    className="text-button button-row gap-x-small view-list-text-button-margin"
                                    onClick={() => backToProps()}    
                                >
                                    <ChevronThick
                                        fill={colour.blueBlue03}
                                        className=""
                                    />
                                    <span className="body-regular text-link">
                                        Back to properties
                                    </span>
                                </div>
                                <div className="view-list-top-container">
                                    <div className="view-list-title-container">
                                        <div className="view-list-inner-title-row-container">
                                            <h1 className="heading-large-semibold colour-primary">
                                                {list.title}
                                            </h1>
                                            <IconButton
                                                size="medium"
                                                icon={<Download className="" fill={colour.blueBlue03} />}
                                                disabled={downloadLoading}
                                                clickFunc={downloadProperties}
                                                standardIcon={false}
                                                contextTooltip={true}
                                                contextTooltipBG={"dark"}
                                                contextTooltipText={"Download CSV"}
                                                customClass={null}
                                            />
                                            <IconButton
                                                size="medium"
                                                icon={<Settings01Icon size={16} color={colour.blueBlue03} />}
                                                disabled={false}
                                                clickFunc={openSettings}
                                                standardIcon={false}
                                                contextTooltip={true}
                                                contextTooltipBG={"dark"}
                                                contextTooltipText={"Settings"}
                                                customClass={null}
                                            />
                                        </div>
                                        <span className="body-regular colour-secondary">
                                            {list.properties.length} saved
                                        </span>
                                    </div>
                                    {/* <div className="view-list-sort-container">
                                        <Dropdown
                                            open={dropdownOpen}
                                            icon={Sort}
                                            label={"Sort"}
                                            disabled={false}
                                            options={sortOptions}
                                            multiselect={false}
                                            toggleDropdown={toggleDropdown}
                                            cta={selectSorting}
                                            sidealign={"left"}
                                            topalign={"bottom"}
                                            indexSelect={false}
                                            selectedIndex={null}
                                        />
                                    </div> */}
                                </div>
                            </div>
                            <div className="view-list-properties-grid">
                                {
                                    properties.map((item, index) =>
                                        <NewPropertyCard
                                            item={item}
                                            index={index}
                                            key={index}
                                            userDetails={userData}
                                            formatter={formatterLong}
                                            abstract={true}
                                            viewedProperties={null}
                                            setViewedProperties={null}
                                            cityId={item.cityId}
                                            setHoverCard={null}
                                            propertyRefs={[]}
                                            setCardClick={null}
                                            temporaryId={false}
                                            overlay={true}
                                            setOverlay={null}
                                            propertySearch={false}
                                            partnerView={false}
                                            partnerEditProperty={null}
                                            partnerSoldProperty={null}
                                            partnerData={null}
                                            changeStatus={null}
                                            setShowExitScreen={null}
                                            selectOption={null}
                                            openStatus={null}
                                            chooseStatus={null}
                                            state={null}
                                            favourites={favourites}
                                            setFavourites={setFavourites}
                                            strategy={null}
                                            arrowClicked={null}
                                            imageLimit={10}
                                            bulkActions={true}
                                            selectedProperty={checkSelected(item.zpid)}
                                            toggleSelected={toggleSelected}
                                            note={item.note}
                                            openNoteModal={openNoteModal}
                                            openUnfavouritePropertiesModal={openUnfavouritePropertiesModal}
                                            creativeStrategy={false}
                                            activeMarket={true}
                                            onboarding={false}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    }
                    {
                        selectedProperties.length > 0 && listModal === false &&
                        <BulkAction
                            selectedProperties={selectedProperties}
                            setListModal={setListModal}
                            lists={lists}
                            setSelectedList={setSelectedList}
                            openDeletePropertiesModal={openDeletePropertiesModal}
                            listView={true}
                            properties={properties}
                            setDownloadLoading={setDownloadLoading}
                            setDownloadComplete={setDownloadComplete}
                            setDownloadError={setDownloadError}
                            setDownloadLink={setDownloadLink}
                        />   
                    }
                    {
                        (downloadLoading === true || downloadComplete === true || downloadError === true) &&
                        <Toast
                            type={downloadComplete === true ? "link" : downloadError === true ? "default" : "loading"}
                            label={downloadError === true ? "There was an error downloading your data" : downloadComplete === true ? "Download successful" : "Downloading CSV file"}
                            icon={downloadError === true ? null : downloadComplete === true ? <TickCircle fill={colour.grayScaleWhite} /> : <Download className="" fill={colour.grayScaleWhite} />}
                            standardIcon={false}
                            linkFunc={openCSVDownload}
					        linkText={downloadLink === null ? null : "Click here to view"}
                            closeFunc={closeToast}
                            undoFunc={null}
                        />
                    }
                    {
                        list === null ?
                        null
                        :
                        list.properties.length > propLimit && loading === false &&
                        <PropertiesPagination
                            total={list.properties.length}
                            page={page}
                            setPage={setPage}
                            maxPerPage={propLimit}
                            setPropertiesOnPageChange={setPropertiesOnPageChange}
                        />
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default ViewList;