import React from 'react';
import '../styles/UnableToAccept.css';
import { Header, Footer } from '../components';
import { Button } from '../v4/components';
import { useNavigate } from 'react-router-dom';

function UnableToAccept() {

    const navigate = useNavigate();

    const cta = () => {
        navigate("/available-markets");
    };

    return (
        <div className="unable-to-accept-outer-container">
            <Header />
            <div className="unable-to-accept-inner-container">
                <div className="unable-to-accept-title-container">
                    <h1 className="heading-large-semibold colour-primary text-align-center">
                        Unable to accept new subscribers
                    </h1>
                    <span className="body-regular colour-primary block-text text-align-center">
                        Unfortunately we're not able to accept new subscribers at this time.
                        <br/>
                        Fear not though, we've got something brewing 😊
                    </span>
                </div>
                <div className="unable-to-accept-button-container">
                    <Button
                        buttonType="primary-button"
                        size="large"
                        text={"View free markets"}
                        cta={cta}
                        disabled={false}
                        leftIcon={null}
                        leftIconClass=""
                        rightIcon={null}
                        rightIconClass=""
                        alt=""
                        standardIcon={false}
                        badge={false}
                        badgeVal={null}
                        badgeBG={null}
                        buttonRef={null}
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default UnableToAccept;