import React from 'react';
import ReactDOM from 'react-dom/client';
import ScrollToTop from './components/ScrollToTop';
import Profile from './pages/Profile';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import FAQ from './pages/FAQ';
import About from './pages/About';
import PennyThoughts from './pages/PennyThoughts';
import ErrorPage from './pages/ErrorPage';
import PropertyList from './properties/PropertyList';
import NewBuyBox from './pages/NewBuyBox';
import EmailVerification from './pages/EmailVerification';
import VerifyEmailComplete from './pages/VerifyEmailComplete';
import AvailableMarkets from './pages/AvailableMarkets';
import ForgotPassword from './pages/ForgotPassword';
import NeighbourhoodWatch from './pages/NeighbourhoodWatch';
import RepeatUser from './pages/RepeatUser';
import GenerateURL from './pages/GenerateURL';
import PreviewExpired from './pages/PreviewExpired';
// import Pay from './pages/Pay';
import PaymentSuccess from './pages/PaymentSuccess';
import GodMode from './pages/GodMode';
import Property from './properties/Property';
import UpgradeCityRedirect from './pages/UpgradeCityRedirect';
import CustomPlan from './pages/CustomPlan';
import Pricing from './pages/Pricing';
import ChooseCounties from './pages/ChooseCounties';
import NoCredits from './pages/NoCredits';
import JoinAffiliate from './pages/JoinAffiliate';
import VerifyEmail from './pages/VerifyEmail';
import EmailVerificationError from './pages/EmailVerificationError';
import Home from './pages/Home';
import BuyBoxes from './pages/BuyBoxes';
import Properties from './pages/Properties';
import ViewList from './pages/ViewList';
import RedirectPage from './pages/RedirectPage';
import Settings from './pages/Settings';
import PropertyNotFound from './pages/PropertyNotFound';
import CommercialProperty from './pages/CommercialProperty';
import Welcome from './pages/Welcome';
import ResetPassword from './pages/ResetPassword';
import Recommendations from './pages/Recommendations';
import RecommendationsLoading from './pages/RecommendationsLoading';
import ViewBestMarket from './pages/ViewBestMarket';
// import DescribeYou from './pages/DescribeYou';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit'
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import allReducers from './reducers';
import './styles/App.css';
import mixpanel from 'mixpanel-browser';
import './styles/Variables.css';
import { init as initFullStory } from '@fullstory/browser';
import { PostHogProvider} from 'posthog-js/react'
import TagManager from 'react-gtm-module';
import PropertySearchRedirect from './pages/PropertySearchRedirect';
import UnableToAccept from './pages/UnableToAccept';
// import Development from './pages/Development';
const store = configureStore({ reducer: allReducers });

// Initialize Firebase
const app = initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE).config);
getAnalytics(app);

mixpanel.init(JSON.parse(process.env.REACT_APP_MIXPANEL).key, 
	{
		debug: false,
		record_sessions_percent: 100,
		record_collect_fonts: true,
		record_mask_text_selector: ".mask-text",
		record_mask_text_class: ".mask-text",
		record_block_selector: ".mask-text"
	}
);
initFullStory({ orgId: JSON.parse(process.env.REACT_APP_FULLSTORY).orgId });

const options = {
	api_host: JSON.parse(process.env.REACT_APP_POSTHOG).host
};

const tagManagerArgs = {
    gtmId: JSON.parse(process.env.REACT_APP_GOOGLE).gtmId
};
TagManager.initialize(tagManagerArgs);

const routing = (
	<Provider
	  store={store}
	>
	  	<BrowserRouter>
			<PostHogProvider 
				apiKey={JSON.parse(process.env.REACT_APP_POSTHOG).key}
				options={options}
			>
				<ScrollToTop>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/home" element={<Home />} />
						<Route path="/buy-boxes" element={<BuyBoxes />} />
						<Route path="/new-buy-box" element={<NewBuyBox />} />
						<Route path="/edit-buy-box" element={<NewBuyBox />} />
						<Route path="/properties" element={<Properties />} />
						<Route path="/list/:id" element={<ViewList />} />
						<Route path="/properties-list/:id" element={<PropertyList />} />
						<Route path="/metro-properties-list/:id/:cityId" element={<RedirectPage />} />
						<Route path="/county-properties-list/:id" element={<RedirectPage />} />
						<Route path="/properties/:cityId/:id" element={<Property />} />
						<Route path="/quick-report" element={<RedirectPage />} />
						<Route path="/quick-report/:id" element={<Property />} />
						<Route path="/property-not-found" element={<PropertyNotFound />} />
						<Route path="/commercial-property" element={<CommercialProperty />} />
						<Route path="/sign-up" element={<SignUp />} />
						<Route path="/login" element={<Login />} />
						<Route path="/profile" element={<Profile />} />
						<Route path="/terms-conditions" element={<TermsAndConditions />} />
						<Route path="/privacy-policy" element={<PrivacyPolicy />} />
						<Route path="/faq" element={<FAQ />} />
						<Route path="/about" element={<About />} />
						<Route path="/penny-for-thoughts" element={<PennyThoughts />} />
						<Route path="/email-verification" element={<EmailVerification />} />
						<Route path="/email-verification-complete" element={<VerifyEmailComplete />} />
						<Route path="/email-verification-error" element={<EmailVerificationError />} />
						<Route path="/password-forgotten" element={<ForgotPassword />} />
						<Route path="/reset-password" element={<ResetPassword />} />
						<Route path="/generate-url" element={<GenerateURL />} />
						<Route path="/link-expired" element={<PreviewExpired />} />
						<Route path="/neighborhood-watch/:id" element={<NeighbourhoodWatch />} />
						{/* <Route path="/describe-you" element={<DescribeYou />} /> */}
						{/* <Route path="/how-did-you-hear" element={<HowDidYouHear />} /> */}
						<Route path="/repeat-user" element={<RepeatUser />} />
						<Route path="/success" element={<PaymentSuccess />} />
						<Route path="/my-cities" element={<RedirectPage />} />
						<Route path="/settings" element={<Settings />} />
						<Route path="/preferences" element={<RedirectPage />} />
						<Route path="/pay" element={<UnableToAccept />} />
						<Route path="/pricing" element={<Pricing />} />
						<Route path="/upgrade-city" element={<UpgradeCityRedirect />} />
						<Route path="/pick-city" element={<RedirectPage />} />
						<Route path="/god-mode" element={<GodMode />} />
						<Route path="/custom-plan" element={<CustomPlan />} />
						<Route path="/choose-counties" element={<ChooseCounties />} />
						<Route path="/no-credits" element={<NoCredits />} />
						<Route path="/affiliate" element={<JoinAffiliate />} />
						<Route path="/verify-email" element={<VerifyEmail />} />
						<Route path="/welcome" element={<Welcome />}/>
						<Route path="/recommendations" element={<Recommendations />}/>
						<Route path="/available-markets" element={<AvailableMarkets />} />
						<Route path="/recommendations-loading" element={<RecommendationsLoading />} />
						<Route path="/results" element={<ViewBestMarket />} />
						{/* <Route path="/development/:id" element={<Development />} /> */}

						<Route path="/property-search" element={<PropertySearchRedirect />} />
						<Route path="/property-search/:id" element={<PropertySearchRedirect />} />

						<Route path="*" element={<ErrorPage />} />
					</Routes>
				</ScrollToTop>
			</PostHogProvider>
		</BrowserRouter>
	</Provider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	// <React.StrictMode> {
		routing
	// } </React.StrictMode>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();